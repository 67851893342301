<template>
  <v-card width="500" class="show rounded-lg" elevation="12">
    <!-- Image src should be replaced when backen is ready -->
    <v-img
      :src="getImg"
      max-height="300"
      width="550"
      position="absolute"
    >
      <v-hover v-slot="{hover}">
        <v-icon @click="$emit('close')" x-large :color="hover ? 'black' : ''">{{ closeIcon }}</v-icon>
      </v-hover>
    </v-img>
    <h1 class="px-2">{{ name }}</h1>
    <p class="px-2">{{ description }}</p>
    <base-button
      @click="$emit('close')"
      class="ma-2"
      :options="{ color: this.YColor }"
    >
      {{ $t("form.cancel") }}
    </base-button>
  </v-card>
</template>
<script>
import BaseButton from "@/core/Base/Buttons/BaseButton";

export default {
  name: 'ShowSliderModal',
  components: { BaseButton },
  props: [
    'order',
    'image_url'
  ],
  computed: {
    getImg() {
      return `https://bact-back.usol.ca${this.image_url}`;
    },
  },
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
}
</style>