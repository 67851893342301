import { render, staticRenderFns } from "./ShowSliderModal.vue?vue&type=template&id=356863d6&scoped=true&"
import script from "./ShowSliderModal.vue?vue&type=script&lang=js&"
export * from "./ShowSliderModal.vue?vue&type=script&lang=js&"
import style0 from "./ShowSliderModal.vue?vue&type=style&index=0&id=356863d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356863d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VHover,VIcon,VImg})
