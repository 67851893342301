<template>
  <v-dialog persistent max-width="500px" v-model="isOpen">
    <v-card class="confirm-delete">
      <v-card-title class="headline text-center">{{ $t('deleteModal.confirmMessage') }}</v-card-title>
      <v-card-actions>
        <v-row class="confirm-body">
          <base-button
            class="text-large"
            :options="{ block: false, color: RColor }"
            @click="deleteTraningMethod()"
          >
            {{ $t('deleteModal.yes') }}
          </base-button>
          <base-button
            class="text-large"
            :options="{ block: false }"
            @click="close()"
          >
            {{ $t('deleteModal.no') }}
          </base-button>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";

export default {
  name: "ConfirmDelete",
  components: { BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: Number,
  },

  methods: {
    deleteTraningMethod() {
      this.$emit("handelRequest", {id:this.id,method:'deleteTraning'});
    },
    close() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.text-large {
  font-size: 18px !important;
}

.confirm-delete {
  overflow: hidden !important;
}

.confirm-body {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}
</style>
