<template>
  <v-container class="pa-14">
    <div v-bind:class="{'fade': this.show || this.form}" >
      <v-row class="pb-5 d-flex flex-column">
        <v-col cols="12" class="mb-4 w-100">
          <h1 class="display-3 font-weight-bold mb-3 text-grey text-center">
            {{ $t("Sliders.title") }}
          </h1>
        </v-col>
        <v-progress-linear
          v-show="loading"
          indeterminate
          :color="this.primaryColor"
        ></v-progress-linear>
        <v-col cols="12">
          <v-row class="d-flex flex-wrap justify-center">
            <Slider 
            v-for="(slider, index) in this.sliders" :key="index" :slider="slider"
            @show="openModal({ slider: slider, type: 'show' })" 
            @edit="openModal({ slider: slider, type: 'form', formType: 'edit' })"
            />
            <AddSliderBtn @add="openModal( { type: 'form', formType: 'add' })"/>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div v-if="show">
      <ShowSliderModal 
        @close="closeForm()" 
        class="modal" 
        :order="this.modalOrder" 
        :image_url="this.modalImageUrl" 
        :id="this.modalId"/>
    </div>

    <div v-if="form">
      <FormSliderModal 
        @add="newSlider" 
        @edit="updateSlider" 
        @delete="removeSlider" 
        @close="closeForm()" 
        class="modal" 
        :formType="this.formType"
        :order="this.modalOrder" 
        :image_url="this.modalImageUrl" 
        :id="this.modalId"/>
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Slider from './Components/Slider'
import AddSliderBtn from './Components/AddSliderBtn'
import ShowSliderModal from './Components/ShowSliderModal'
import FormSliderModal from './Components/FormSliderModal'

export default {
  name: 'Sliders',

  components: {
    Slider,
    AddSliderBtn,
    ShowSliderModal,
    FormSliderModal,
  },

  created () {
    this.loading = true
    this.getSliders().then(() => {
      this.loading = false
    });
  },

  data () {
    return {
      loading: false,
      show: false, // to toggle the show partner modal
      form: false, // to toggle the form (edit/add partner) modal
      modalOrder: '', // to use as prop in edit or add modal
      modalImageUrl: [], // to use as prop in edit or add modal
      modalId: '', // to use as prop in edit or add modal
      formType: '' // to use as prop in edit or add modal to determine whether it is edit or add new partner
    }
  },
  
  computed: mapState("DashBoard/Sliders", ["sliders"]),

  methods: {
    ...mapActions("DashBoard/Sliders", ["getSliders", "addSlider", "editSlider", "deleteSlider"]),

    /**
     * @author Ibrahim Zanbily
     * @task BACT-198-test2
     * @desc displays a modal to show a partner when clicked or edit a partner depending on form type
     */
    openModal (modal) {
      if (modal.slider){
        this.modalOrder = modal.slider.order;
        this.modalImageUrl = modal.slider.image_url;
        this.modalId = modal.slider.id;
      }
      if (modal.type == 'show') {
        this.show = true;
      } else if (modal.type == 'form') {
        this.form = true,
        this.formType = modal.formType
      }
    },

    updateSlider (payload) {
      console.log(payload);
      this.editSlider(payload);
      this.closeForm();
    },

    newSlider (payload) {
      console.log(payload);
      this.addSlider(payload);
      this.closeForm();
    },

    removeSlider (payload) {
      console.log(payload);
      this.deleteSlider(payload.id);
      this.closeForm();
    },
    closeForm () {
      this.modalOrder = '';
      this.modalImageUrl = null;
      this.modalId = '';
      this.form = false;
      this.show = false;
      this.formType = '';
    },
  },
}
</script>
<style lang="scss" scoped>

.modal {
  position: fixed;
  top: 8%;
  left: 33%;
  height: 88vh;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  animation: enterModal 0.3s ease-in-out;
}
.fade {
  opacity: 0.5;
}
@keyframes enterModal {
  from {
    top: -60%;
  }
  to {
    top: 8%;
  }
}
</style>