<template>
  <div class="partner ma-2">
    <v-card width="200" height="260" class="rounded-lg" elevation="12">
      <!-- src to be changed when fetching real images -->
      <v-img
        :src="getImg"
        height="200px"
        :alt="slider.image_url"
      >
        <v-card-title>
          <v-row justify="space-between" class="px-2">
            <v-hover v-slot="{hover}">
              <v-icon @click="$emit('edit')" :color="hover ? 'black' : ''">{{ editIcon }}</v-icon>
            </v-hover>
            <v-hover v-slot="{hover}">
              <v-icon @click="$emit('show')" :color="hover ? 'black' : ''">{{ eyeIcon }}</v-icon>
            </v-hover>
          </v-row>
        </v-card-title>
      </v-img>
      <v-card-title>
        {{ slider.order }}
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Slider',
  props: [
    'slider'
  ],
  computed: {
    getImg() {
      return `https://bact-back.usol.ca${this.slider.image_url}`;
    },
  },
}
</script>