<template>
  <v-card width="500" class="pa-2 rounded-lg" elevation="12">
    <v-form ref="form">
      <v-hover v-slot="{ hover }">
        <v-icon @click="close()" x-large :color="hover ? 'black' : ''">{{
          closeIcon
        }}</v-icon>
      </v-hover>

      <v-row>
        <v-subheader>{{ this.$t("Sliders.image") }}</v-subheader>
        <v-file-input
          accept="image/*"
          :prepend-icon="cameraIcon"
          v-model="newImageUrl"
          :rules="formType === 'add' ? imageRules : noRules"
        ></v-file-input>
      </v-row>

      <v-row>
        <v-subheader>{{ this.$t("Sliders.order") }}</v-subheader>
        <v-text-field
          class="main-color text-lg pa-1"
          text-center
          v-model="newOrder"
          :rules="nameRules"
        ></v-text-field>
      </v-row>

      <v-row justify="space-around">
        <div class="d-flex justify-center">
          <base-button
            class="w-200"
            @click="save()"
            :options="{ isLoading: false }"
          >
            {{ $t("form.save") }}
          </base-button>
          <base-button
            @click="close()"
            class="mx-2"
            :options="{ color: this.YColor }"
          >
            {{ $t("form.cancel") }}
          </base-button>
        </div>
        <base-button
          v-if="this.formType == 'edit'"
          @click="deleteConfirm = newId"
          class="mx-2"
          :options="{ color: this.RColor }"
        >
          {{ $t("Sliders.deleteSlider") }}
        </base-button>
      </v-row>
    </v-form>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="deleteConfirm = -1"
      @handelRequest="$emit('delete', { id: id })"
    ></confirm-delete>
  </v-card>
</template>
<script>
import BaseButton from "@/core/Base/Buttons/BaseButton";
import ConfirmDelete from "./confirmDelete";

export default {
  name: "FormSliderModal",
  components: { BaseButton, ConfirmDelete },
  props: [
    "formType",
    "order",
    "image_url",
    "id", // the current index to use in the edit case
  ],
  data() {
    return {
      nameRules: [(v) => !!v || this.$t("required.field")],
      descriptionRules: [(v) => !!v || this.$t("required.text")],
      imageRules: [(v) => !!v || this.$t("required.field")],
      noRules: [],
      deleteConfirm: -1,
      newOrder: this.order,
      newImageUrl: [],
      newId: this.id,
    };
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.formType == "edit") {
        this.$emit("edit", {
          id: this.newId,
          order: this.newOrder,
          image_url: this.newImageUrl
        });
      } else if (this.formType == "add") {
        this.$emit("add", {
          order: this.newOrder,
          image_url: this.newImageUrl,
        });
      }
    },
    close() {
      this.$refs.form.resetValidation();
      this.$emit("close");
    },
  },
};
</script>